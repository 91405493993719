/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { MenuItem } from "primeng/api/menuitem";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { ChannelType } from "../core/sale-channel/sale-channel";
import { APP_BRAND } from "../utility/white-label";
import { Station_Type } from "../core/settings/store-settings/station/station";

function areAllItemsVisible(items: MenuItem[]): boolean {
  // return true if any item is visible. if all are false, return false.
  return items.some((item) => item?.visible === true);
}

@Injectable({
  providedIn: "root",
})
export class AppMenuService {
  branding = APP_BRAND;
  constructor(private authService: AuthService, private appSettingsService: AppSettingsStorageService) {}

  async isSaleChannelConnected(storeId, ChannelType) {
    const result = await this.appSettingsService.getSaleChannelsByStoreId(storeId, ChannelType).toPromise();
    return result ? false : result.rows[0].isConnected;
  }

  getAppMenu(storeId?: number, zoneId?: number, defaultStockId?: number): any[] {
    const _authService = this.authService;
    return [
      {
        // label: 'Favorites', icon: 'pi pi-fw pi-home',
        items: [
          storeId
            ? {
                id: "SubMenuSell",
                label: "Sell",
                icon: "shopping_cart",
                routerLink: ["sell"],
                items: [
                  {
                    id: "SalesRegister",
                    label: "Sales Register",
                    icon: "shopping_cart",
                    routerLink: ["sell/saleDoc/0"],
                    disabled: !_authService.hasEnablePermit("Sell_Menu_Sales_Register"),
                    visible: _authService.hasVisiblePermit("Sell_Menu_Sales_Register"),
                  },
                  {
                    id: "ReturnScreen",
                    label: "Return",
                    icon: "remove_shopping_cart",
                    routerLink: ["sell/saleDoc/returns/0"],
                    disabled: !_authService.hasEnablePermit("Sell_Menu_Returns"),
                    visible: _authService.hasVisiblePermit("Sell_Menu_Returns"),
                  },
                  {
                    id: "Commitments",
                    label: "Commitments",
                    icon: "event_repeat",
                    routerLink: ["sell/saleDoc/commitments", storeId],
                    disabled: !_authService.hasEnablePermit("Sell_Menu_Commitments"), //TODO
                    visible: _authService.hasVisiblePermit("Sell_Menu_Commitments"),
                  },
                  {
                    id: "ParkedTransactions",
                    label: "Parked Transactions",
                    icon: "bookmark",
                    // routerLink: ['/saleDoc/documents/parked-sales', storeId],
                    routerLink: ["sell/saleDoc/parked-sales", storeId],
                    disabled: !_authService.hasEnablePermit("Sell_Menu_Parked_Transactions"),
                    visible: _authService.hasVisiblePermit("Sell_Menu_Parked_Transactions"),
                  },
                  {
                    id: "Cashout",
                    label: "Cashout",
                    icon: "attach_money",
                    routerLink: ["sell/cashout"],
                    // routerLink: ['/cashout', cashoutId],
                    disabled: !_authService.hasEnablePermit("Sell_Menu_Cashout"),
                    visible: _authService.hasVisiblePermit("Sell_Menu_Cashout"),
                  },
                ],
                get visible() {
                  return areAllItemsVisible(this.items);
                },
              }
            : null,
          {
            id: "SubMenuHistory",
            label: "History",
            icon: "history",
            routerLink: ["/history"],
            disabled: !_authService.hasEnablePermit("Main_Menu_History"),
            items: [
              {
                id: "SalesRevisit",
                label: "Revisit",
                icon: "receipt",
                routerLink: ["/history/documents/revisit", storeId],
                disabled: !_authService.hasEnablePermit("Sell_Menu_Revisit"),
                visible: _authService.hasVisiblePermit("Sell_Menu_Revisit"),
              },
              {
                id: "AllUpcomingOrders",
                label: "Fulfillment",
                icon: "takuicon-layer-group-solid",
                iconImpl: "style-class",
                routerLink: ["/history/all-upcoming-orders", storeId],
                disabled: !_authService.hasEnablePermit("Sell_Menu_All_Upcoming_Orders"),
                visible: _authService.hasVisiblePermit("Sell_Menu_All_Upcoming_Orders"),
              },
              {
                id: "CashoutHistory",
                label: "Cashout History",
                icon: "takuicon-cashout-history",
                iconImpl: "style-class",
                routerLink: ["/history/cashout-history"],
                disabled: !_authService.hasEnablePermit("Sell_Menu_Cashout_History"),
                visible: _authService.hasVisiblePermit("Sell_Menu_Cashout_History"),
              },
            ],
            get visible() {
              return (
                areAllItemsVisible(this.items) && _authService && _authService.hasVisiblePermit("Main_Menu_History")
              );
            },
          },

          {
            id: "SubMenuSalesChannels",
            label: "Sales Channels",
            icon: "takuicon-compress-arrows-alt-solid",
            iconImpl: "style-class",
            routerLink: ["/sale-channel"],
            disabled: !_authService.hasEnablePermit("Main_Menu_Sales_Channels"),
            items: [
              !storeId
                ? {
                    id: "AdminMode",
                    label: "Admin Mode",
                    icon: "business",
                    routerLink: ["/sale-channel/admin-mode"],
                    //queryParams: { title: 'Admin Mode' },
                    visible: _authService.hasVisiblePermit("SalesChannels_Menu_AdminMode"),
                    disabled: !_authService.hasEnablePermit("SalesChannels_Menu_AdminMode"),
                  }
                : null,
              storeId
                ? {
                    id: "AllOnlineOrders",
                    label: "All Online Orders",
                    icon: "takuicon-layer-group-solid",
                    iconImpl: "style-class",
                    routerLink: ["/sale-channel/orders", storeId],
                    visible: _authService.hasVisiblePermit("SalesChannels_Menu_AllOnlineOrders"),
                    disabled: !_authService.hasEnablePermit("SalesChannels_Menu_AllOnlineOrders"),
                  }
                : {
                    id: "AllOnlineOrders",
                    label: "All Online Orders",
                    icon: "takuicon-layer-group-solid",
                    iconImpl: "style-class",
                    routerLink: ["/sale-channel/orders", 0],
                    visible: _authService.hasVisiblePermit("SalesChannels_Menu_AllOnlineOrders"),
                    disabled: !_authService.hasEnablePermit("SalesChannels_Menu_AllOnlineOrders"),
                  },
              storeId
                ? {
                    id: "TAKUeCommerce",
                    label: this.branding.appMenuComponent.eCommerce,
                    icon: "takuicon-shopping-bag-solid",
                    iconImpl: "style-class",
                    routerLink: ["/sale-channel/inventory", storeId],
                    queryParams: {
                      title: "TAKU eCommerce",
                      queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId: zoneId }),
                    },
                    visible: _authService.hasVisiblePermit("Apps_Menu_Taku_eCommerce"),
                    disabled: !_authService.hasEnablePermit("Apps_Menu_Taku_eCommerce"),
                  }
                : null,
              // storeId ? {
              //   id: 'WooCommerce',
              //   label: 'WooCommerce',
              //   icon: 'takuicon-wordpress-brands',
              //   iconImpl: 'style-class',
              //   routerLink: ['/sale-channel/settings', storeId],
              //   queryParams: { title: 'WooCommerce' },
              //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_WooCommerce'),
              //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_WooCommerce'),
              // } : null,
              // storeId ? {
              //   id: 'Facebook',
              //   label: 'Facebook',
              //   icon: 'takuicon-facebook-brands',
              //   iconImpl: 'style-class',
              //   routerLink: ['/sale-channel/settings', storeId],
              //   queryParams: { title: 'Facebook' },
              //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_Facebook'),
              //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_Facebook'),
              // } : null,
              // storeId ? {
              //   id: 'Instagram',
              //   label: 'Instagram',
              //   icon: 'takuicon-instagram-square-brands',
              //   iconImpl: 'style-class',
              //   routerLink: ['/sale-channel/settings', storeId],
              //   queryParams: { title: 'Instagram' },
              //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_Instagram'),
              //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_Instagram'),
              // } : null,
              // storeId ? {
              //   id: 'Amazon',
              //   label: 'Amazon',
              //   icon: 'takuicon-amazon-brands',
              //   iconImpl: 'style-class',
              //   routerLink: ['/sale-channel/settings', storeId],
              //   queryParams: { title: 'Amazon' },
              //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_Amazon'),
              //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_Amazon'),
              // } : null,
            ],
            get visible() {
              return (
                areAllItemsVisible(this.items) &&
                _authService &&
                _authService.hasVisiblePermit("Main_Menu_Sales_Channels")
              );
            },
          },

          storeId
            ? {
                id: "SubMenuKiosks",
                label: "Kiosks",
                icon: "stay_primary_portrait",
                routerLink: ["/kiosk"],
                disabled: !_authService.hasEnablePermit("Main_Menu_Kiosks"),
                visible:
                  this.appSettingsService.getStation()?.stationType === Station_Type.kiosk &&
                  _authService.hasVisiblePermit("Main_Menu_Kiosks"),
                items: [
                  {
                    id: "PriceChecker",
                    label: "Price Checker",
                    icon: "circle",
                    routerLink: ["/kiosk/priceChecker"],
                    disabled: true,
                  },
                  {
                    id: "SelfCheckout",
                    label: "Self-Checkout",
                    icon: "radio_button_checked",
                    routerLink: ["/kiosk/selfCheckout/landingPage"],
                    disabled: false,
                  },
                  {
                    id: "OrderPickUp",
                    label: "Order Pick Up",
                    icon: "circle",
                    routerLink: ["/kiosk/orderPickup"],
                    disabled: true,
                  },
                ],
              }
            : null,

          {
            id: "SubMenuAccounts",
            label: "Accounts",
            icon: "supervisor_account",
            routerLink: ["/accounts"],
            items: [
              {
                id: "PersonalAccounts",
                label: "Personal Accounts",
                icon: "person",
                routerLink: ["/accounts/personal/model-list/w/personalAccount"],
                queryParams: { title: "Personal Account" },
                visible: _authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts"),
                disabled: !_authService.hasEnablePermit("Accounts_Menu_Personal_Accounts"),
              },
              {
                id: "CommercialAccounts",
                label: "Commercial Accounts",
                icon: "business",
                routerLink: ["/accounts/commercial/model-list/w/commercialAccount"],
                queryParams: { title: "Commercial Account" },
                visible: _authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts"),
                disabled: !_authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts"),
              },
              // {
              //   label: 'Vendors',
              //   icon: 'work_outline',
              //   routerLink: ['/model-list/w/commercialAccount']
              // }
            ],
            get visible() {
              return areAllItemsVisible(this.items);
            },
          },

          {
            id: "SubMenuInventory",
            label: "Inventory",
            icon: "local_offer",
            routerLink: ["/inventory-list"],
            items: [
              {
                id: "InventoryList",
                label: "Inventory List",
                icon: "fiber_manual_record",
                routerLink: ["/inventory-list/model-list/w/inventory"],
                queryParams: {
                  queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId }),
                },
                disabled: !_authService.hasEnablePermit("Inventory_Menu_Inventory_List"),
                visible: _authService.hasVisiblePermit("Inventory_Menu_Inventory_List"),
              },
              {
                id: "PriceLevels",
                label: "Price Levels",
                icon: "price_change",
                routerLink: ["inventory-list/price-level/model-list/d/priceLevel"],
                queryParams: {
                  queryExtraParams: JSON.stringify({ zoneId }),
                },
                // TODO
                // disabled: !_authService.hasEnablePermit('Inventory_Menu_Stock_Receiving'),
                // visible: _authService.hasVisiblePermit('Inventory_Menu_Stock_Receiving'),
              },
              {
                id: "Promotions",
                label: "Promotions",
                icon: "price_check",
                routerLink: ["inventory-list/promotion/model-list/d/promotion"],
                queryParams: {
                  queryExtraParams: JSON.stringify({ zoneId }),
                },
              },
              // {
              //   id: 'InventoryIn',
              //   label: 'Inventory Transfer In',
              //   icon: 'fiber_manual_record',
              //   routerLink: ['/inventoryDoc/transfer-in/list', zoneId],
              // },
              // {
              //   id: 'InventoryOut',
              //   label: 'Inventory Transfer Out',
              //   icon: 'fiber_manual_record',
              //   routerLink: ['/inventoryDoc/transfer-out/list', zoneId],
              // },
              {
                id: "StockAdjustment",
                label: "Stock Adjustment",
                icon: "fiber_manual_record",
                routerLink: ["/"],
                disabled: true, // !_authService.hasEnablePermit("Inventory_Menu_Stock_Adjustment"),
                visible: _authService.hasVisiblePermit("Inventory_Menu_Stock_Adjustment"),
              },
              // {
              //   id: 'StockCount',
              //   label: 'Stock Count',
              //   icon: 'fiber_manual_record',
              //   routerLink: ['/'],
              // },
            ],
            get visible() {
              return areAllItemsVisible(this.items);
            },
          },

          {
            id: "SubMenuPurchasing",
            label: "Purchasing",
            icon: "request_quote",
            routerLink: ["/purchasing-list"],
            items: [
              {
                id: "PurchaseOrders",
                label: "Purchase Orders",
                icon: "fiber_manual_record",
                routerLink: ["/purchasing-list/purchaseDoc/purchase-order/list", zoneId],
                disabled: !_authService.hasEnablePermit("Purchasing_Menu_Purchase_Orders"),
                visible: _authService.hasVisiblePermit("Purchasing_Menu_Purchase_Orders"),
              },
              {
                id: "StockReceiving",
                label: "Stock Receiving",
                icon: "fiber_manual_record",
                // routerLink: ['/purchasing-list/inventoryDoc/stock-receiving/list', zoneId],
                routerLink: ["/purchasing-list/inventoryDoc/stock-receiving/list", zoneId],
                disabled: !_authService.hasEnablePermit("Purchasing_Menu_Stock_Receiving"),
                visible: _authService.hasVisiblePermit("Purchasing_Menu_Stock_Receiving"),
              },
            ],
            get visible() {
              return areAllItemsVisible(this.items);
            },
          },

          {
            id: "Reports",
            label: "Reports",
            icon: "assessment",
            routerLink: ["/reports/dailySales"],
            disabled: !_authService.hasEnablePermit("Reports_Menu"),
            visible: _authService.hasVisiblePermit("Reports_Menu"),
          },

          {
            id: "SubMenuApps",
            label: "Apps",
            icon: "takuicon-integrations-settings",
            iconImpl: "style-class",
            routerLink: ["/integrations"],
            disabled: !_authService.hasEnablePermit("Main_Menu_Apps"),
            items: [
              {
                id: "BrowseApps",
                label: "Browse Apps",
                icon: "apps",
                routerLink: ["/integrations/all"],
              },

              {
                id: "MyApps",
                label: "My Apps",
                icon: "takuicon-integrations-settings",
                iconImpl: "style-class",
                routerLink: ["/integrations/my-apps", storeId],
                items: this.getUserEnabledApps(storeId, defaultStockId, zoneId),
              },
            ],
            visible: _authService.hasVisiblePermit("Main_Menu_Apps"),
          },

          {
            id: "SubMenuSettings",
            label: "Settings",
            icon: "settings",
            routerLink: ["/settings"],
            items: [
              {
                id: "BusinessSettings",
                label: "Business Settings",
                icon: "business",
                routerLink: ["settings/business-settings"],
                visible:
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Business_Details") ||
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Financial") ||
                  _authService.hasVisiblePermit("Settings_Settings_Menu_Business_Settings_Taxes") ||
                  _authService.hasVisiblePermit("Purchasing_Menu_Purchase_Orders") ||
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Business_Formats") ||
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Unit_of_Measure") ||
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Inventory_Categories") ||
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Account_Groups") ||
                  _authService.hasVisiblePermit("Settings_Menu_Business_Settings_Brands"),
              },
              {
                id: "SubMenuZones",
                label: "Zones",
                icon: "takuicon-create-region-settings",
                iconImpl: "style-class",
                routerLink: ["/settings/zones"],
                items: [
                  {
                    id: "ManageZones",
                    label: "Manage Zones",
                    icon: "terrain",
                    routerLink: ["/settings/zones/manage-zones/n/zone"],
                    disabled: !_authService.hasEnablePermit("Settings_Menu_Zones_Manage_Zones"),
                    visible: _authService.hasVisiblePermit("Settings_Menu_Zones_Manage_Zones"),
                  },
                  zoneId
                    ? {
                        id: "CurrentZone",
                        label: "Current Zone",
                        icon: "near_me",
                        routerLink: ["/settings/zones/current-zone", zoneId],
                        visible:
                          _authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Denominations") ||
                          _authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Tender_Types") ||
                          _authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Shippers") ||
                          _authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Stock") ||
                          _authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats"),
                      }
                    : null,
                ].filter(Boolean),
                get visible() {
                  return areAllItemsVisible(this.items);
                },
              },
              {
                id: "SubMenuBackOffices",
                label: "Back Offices",
                icon: "desktop_mac",
                routerLink: ["/settings/backOffices"],
                disabled: !_authService.hasEnablePermit("Settings_Menu_Back_Offices"),
                visible: _authService.hasVisiblePermit("Settings_Menu_Back_Offices"),
                items: [
                  {
                    label: "Manage Back Office",
                    icon: "takuicon-manage-locations-settings",
                    iconImpl: "style-class",
                    routerLink: ["/settings/backOffices/manage/s/backOffice"],
                    visible: true,
                  },
                  // {
                  //   label: 'Current Back Office',
                  //   icon: 'takuicon-active-location-settings',
                  //   iconImpl: 'style-class',
                  //   routerLink: [],
                  //   visible:true
                  // }
                ],
              },
              {
                id: "SubMenuStores",
                label: "Stores",
                icon: "store",
                routerLink: ["/settings/stores"],
                items: [
                  {
                    id: "ManageStores",
                    label: "Manage Stores",
                    icon: "takuicon-manage-locations-settings",
                    iconImpl: "style-class",
                    routerLink: ["/settings/stores/manage-stores/s/store"],
                    disabled: !_authService.hasEnablePermit("Settings_Menu_Stores_Manage_Stores"),
                    visible: _authService.hasVisiblePermit("Settings_Menu_Stores_Manage_Stores"),
                  },
                  storeId
                    ? {
                        id: "CurrentStore",
                        label: "Current Store",
                        icon: "takuicon-active-location-settings",
                        iconImpl: "style-class",
                        // routerLink: ['settings/location-settings', storeId],
                        routerLink: ["settings/stores/current-store/location-settings", storeId],
                        visible:
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Formats") ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Store_Policies") ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Return_Settings") ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Payment_Settings") ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Cashout_Settings") ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Settings") ||
                          _authService.hasVisiblePermit(
                            "Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Tape)"
                          ) ||
                          _authService.hasVisiblePermit(
                            "Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Tape)"
                          ) ||
                          _authService.hasVisiblePermit(
                            "Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Full_Page)"
                          ) ||
                          _authService.hasVisiblePermit(
                            "Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Full_Page)"
                          ) ||
                          _authService.hasVisiblePermit(
                            "Settings_Menu_Stores_Current_Store_Gift_Receipt_Builder_(Tape)"
                          ) ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Email_Settings") ||
                          _authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Kiosk_Settings"),
                      }
                    : null,
                ],
                get visible() {
                  return areAllItemsVisible(this.items);
                },
              },
              {
                id: "SubMenuStations",
                label: "Stations",
                icon: "takuicon-manage-stations-settings",
                iconImpl: "style-class",
                disabled: !_authService.hasEnablePermit("Settings_Menu_Stations"),
                visible: _authService.hasVisiblePermit("Settings_Menu_Stations"),
                routerLink: ["/settings/stations"],
                items: [
                  {
                    id: "ManageStations",
                    label: "Manage Stations",
                    icon: "takuicon-manage-stations-settings",
                    iconImpl: "style-class",
                    routerLink: ["/settings/stations/model-list/n/station", storeId],
                    visible: true,
                  },
                  // stationId ? {
                  //   label: 'Current Station',
                  //   icon: 'takuicon-active-station-settings',
                  //   iconImpl: 'style-class',
                  //   routerLink: ['']
                  // } : null,
                ],
              },
              {
                id: "UserManagement",
                label: "User Management",
                icon: "group",
                routerLink: ["settings/user-management"],
                disabled: !_authService.hasEnablePermit("Settings_Menu_User_Management"),
                visible:
                  _authService.hasVisiblePermit("Settings_Menu_User_Management") &&
                  (_authService.hasVisiblePermit("Settings_Menu_User_Management_Manage_Users") ||
                    _authService.hasVisiblePermit("Settings_Menu_User_Management_Manage_Roles")),
              },
              {
                id: "Import",
                label: "Import",
                icon: "import_export",
                routerLink: ["settings/importer"],
                disabled: !_authService.hasEnablePermit("Settings_Menu_Import"),
                visible: _authService.hasVisiblePermit("Settings_Menu_Import"),
              },
            ],
            get visible() {
              return areAllItemsVisible(this.items);
            },
          },
        ],
      },
    ];
  }

  // private getUserEnabledApps(storeId, defaultStockId, zoneId): MenuItem[] {
  private getUserEnabledApps(storeId, defaultStockId, zoneId) {
    return [
      {
        id: "TAKUeCommerceSettings",
        label: this.branding.appMenuComponent.eCommerce,
        icon: "takuicon-shopping-bag-solid",
        iconImpl: "style-class",
        routerLink: ["/integrations/my-apps/sale-channel/settings", storeId],
        queryParams: {
          title: "TAKU eCommerce",
          queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId: zoneId }),
        },
        visible: this.authService.hasVisiblePermit("Apps_Menu_Taku_eCommerce"),
        disabled: !this.authService.hasEnablePermit("Apps_Menu_Taku_eCommerce"),
      },
      {
        id: "Takupay",
        label: "TAKU Pay",
        icon: "pi pi-credit-card",
        iconImpl: "style-class",
        routerLink: ["/integrations/my-apps/takupay"],
        visible: this.authService.hasVisiblePermit("Apps_Menu_Taku_Pay"),
        disabled: !this.authService.hasEnablePermit("Apps_Menu_Taku_Pay"),
      },
      {
        id: "Moneris",
        label: "Moneris",
        icon: "pi pi-credit-card",
        iconImpl: "style-class",
        routerLink: ["/integrations/my-apps/payment-gateways/list/Moneris", storeId],
        queryParams: {
          title: "Moneris",
          queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId: zoneId }),
        },
        visible: this.authService.hasVisiblePermit("Apps_Menu_Moneris"),
        disabled: !this.authService.hasEnablePermit("Apps_Menu_Moneris"),
      },
      // {
      //   id: "PayRoc",
      //   label: "PayRoc",
      //   icon: "takuicon-money",
      //   routerLink: ["/integrations/my-apps/payment-gateways/list/Econduit", storeId],
      //   queryParams: {
      //     title: "Econduit",
      //     queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId: zoneId }),
      //   },
      //   visible: false,
      //   disabled: false,
      // },
      // {
      //   id: "GMB",
      //   label: "Google My Business (GMB)",
      //   icon: "takuicon-gmb",
      //   iconImpl: "style-class",
      //   routerLink: ["/integrations/my-apps/gmb/main"],
      //   visible: this.authService.hasVisiblePermit("Apps_Menu_Google_My_Business_(GMB)_Card"),
      //   disabled: !this.authService.hasEnablePermit("Apps_Menu_Google_My_Business_(GMB)_Card"),
      // },
      // {
      //   id: "GoogleShopping",
      //   label: "Google Shopping",
      //   icon: "takuicon-google-integration",
      //   iconImpl: "style-class",
      //   routerLink: storeId
      //     ? ["/integrations/my-apps/google-shopping/products"]
      //     : ["/integrations/my-apps/google-shopping/settings"],
      //   visible: this.authService.hasVisiblePermit("Apps_Menu_Google_Shopping_Card"),
      //   disabled: !this.authService.hasEnablePermit("Apps_Menu_Google_Shopping_Card"),
      // },
      // {
      //   id: 'Shopify',
      //   label: 'Shopify',
      //   icon: 'takuicon-shopify-brands',
      //   iconImpl: 'style-class',
      //   routerLink: ['/integrations/sale-channel/shopify/settings', storeId],
      //   queryParams: { title: 'TAKU eCommerce',queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId: zoneId }) },
      //   visible: true, // _authService.hasVisiblePermit('SalesChannels_Menu_TAKUeCommerce'),
      //   disabled: true, // !_authService.hasEnablePermit('SalesChannels_Menu_TAKUeCommerce'),
      // },
      // {
      //   id: 'WooCommerce',
      //   label: 'WooCommerce',
      //   icon: 'takuicon-wordpress-brands',
      //   iconImpl: 'style-class',
      //   routerLink: ['/sale-channel/settings', storeId],
      //   queryParams: { title: 'WooCommerce' },
      //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_WooCommerce'),
      //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_WooCommerce'),
      // },
      // {
      //   id: 'Facebook',
      //   label: 'Facebook',
      //   icon: 'takuicon-facebook-brands',
      //   iconImpl: 'style-class',
      //   routerLink: ['/sale-channel/settings', storeId],
      //   queryParams: { title: 'Facebook' },
      //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_Facebook'),
      //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_Facebook'),
      // },
      // {
      //   id: 'Instagram',
      //   label: 'Instagram',
      //   icon: 'takuicon-instagram-square-brands',
      //   iconImpl: 'style-class',
      //   routerLink: ['/sale-channel/settings', storeId],
      //   queryParams: { title: 'Instagram' },
      //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_Instagram'),
      //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_Instagram'),
      // },
      // {
      //   id: 'Amazon',
      //   label: 'Amazon',
      //   icon: 'takuicon-amazon-brands',
      //   iconImpl: 'style-class',
      //   routerLink: ['/sale-channel/settings', storeId],
      //   queryParams: { title: 'Amazon' },
      //   visible: _authService.hasVisiblePermit('SalesChannels_Menu_Amazon'),
      //   disabled: true // !_authService.hasEnablePermit('SalesChannels_Menu_Amazon'),
      // },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
