/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SelectItem, ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AppSettingsStorageService, LoginType } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { AuthService } from "../../../shared/services/auth.service";
import { DBService } from "../../../shared/services/db.service";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { DBCashoutService } from "../../cashout/db-cashout.service";
import { BackOffice } from "../../settings/backOffice-settings/backOffice/backOffice";
import { Station } from "../../settings/store-settings/station/station";
import { Store } from "../../settings/store-settings/store/store";
import { Zone } from "../../settings/zone-settings/Zone";
import { UserService } from "../user/user.service";
import { Login } from "./login";
import { User } from "../user/user";
import { UserRole } from "../user-role/user-role";
import { BlockingUIService } from "src/app/shared/services/ui/blocking-ui.service";
import { DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { TakuInputComponent } from "src/app/taku-ui/taku-input/taku-input.component";
import { IdleService } from "src/app/idle.service";
import { environment } from "src/environments/environment";
import { APP_BRAND } from "src/app/utility/white-label";

enum LoginSteps {
  SELECT_STORE_ADDRESS = 1,
  ENTER_CREDENTIALS = 2,
  SELECT_LOCATION = 3,
}

enum LocationTypes {
  BACK_OFFICE = 1,
  STORE = 2,
  ADMIN_MODE = 3,
}

const LocationTypeToString = (t: LocationTypes): LoginType => {
  switch (t) {
    case LocationTypes.ADMIN_MODE:
      return "Admin";
    case LocationTypes.BACK_OFFICE:
      return "BackOffice";
    case LocationTypes.STORE:
      return "Store";
  }
};

@Component({
  selector: "taku-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [DialogService],
})
export class LoginComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  _object = new Login();
  appVersion = environment.common.APP_VERSION;

  _myForm: UntypedFormGroup;
  _validation: any;
  _mapFormControls: any;
  _loginErrors: { message: string };

  backOffices_lookup$: Observable<SelectItem[]>;
  stores_lookup$: Observable<SelectItem[]>;
  stations_lookup$: Observable<SelectItem[]>;
  loggedInUser: User;
  storeSelectionForm: UntypedFormGroup;
  addressSelectionForm: UntypedFormGroup;
  currentStep = LoginSteps.SELECT_STORE_ADDRESS;
  LoginSteps = LoginSteps;
  LocationTypes = LocationTypes;
  _getStartedClicked = false;
  _signInClicked = false;
  progressBar: DynamicDialogRef;
  _isFetchingData = false;

  copyrightYear = 2022;

  branding = APP_BRAND;

  readonly stepsTitles: { [key: number]: string } = {
    [LoginSteps.SELECT_STORE_ADDRESS]: "Log In",
    [LoginSteps.ENTER_CREDENTIALS]: "Login",
    [LoginSteps.SELECT_LOCATION]: "Select Login Type",
  };

  // variable for changing class
  containerSizeFlag = 2;

  @ViewChild("loginPasswordField") passwordFieldComponent: TakuInputComponent;

  constructor(
    public fb: UntypedFormBuilder,
    public auth: AuthService,
    private router: Router,
    private messageService: MessageService,
    private alertService: AlertMessagesService,
    private dbService: DBService,
    private userService: UserService,
    private appSettingsService: AppSettingsStorageService,
    private cashoutService: DBCashoutService,
    private confirmationService: ConfirmationService,
    private blockUIService: BlockingUIService,
    private dialogService: DialogService,
    private idleService: IdleService
  ) {
    this.createForm();
  }

  getAccountName() {
    return this.appSettingsService.getAccountName();
  }

  get locationTypeCtrl() {
    return this.storeSelectionForm.get("locationType");
  }

  onStoreAddressConfirmed() {
    const accountName = this.addressSelectionForm.get("accountAddress").value;
    this._isFetchingData = true;
    this.subsList.push(
      this.auth.accountNameExists$(accountName).subscribe({
        next: (response) => {
          this._isFetchingData = false;
          this.appSettingsService.setAccountName(accountName);
          // Clear auto-logout message after logging in
          this.messageService.clear();

          if (!this.auth.isLoggedIn()) {
            this.currentStep = LoginSteps.ENTER_CREDENTIALS;
          } else {
            this.onChangeToLoginTypeScreen();
          }
        },
        error: (error) => {
          this._isFetchingData = false;
          if (error.error && error.error.message) this._loginErrors = error.error;
          else this._loginErrors = { message: "Error: Could not verify if the provided account name exists." };
        },
      })
    );
  }

  hideForgotPasswordLink() {
    const accountNameInLocalStorage = this.appSettingsService.getAccountName();

    return (
      accountNameInLocalStorage && accountNameInLocalStorage === this.addressSelectionForm.get("accountAddress").value
    );
  }

  onChangeToLoginTypeScreen(): void {
    // use this extra parameter to get nested property (userRoles) of the user.
    const extraUserParam = {
      includes: "userRole",
    };

    if (!this.auth.isLoggedIn() || !this.loggedInUser) {
      return;
    }

    // Get the logged in user and their roles.
    this.subsList.push(
      this.dbService.getRow("user", this.loggedInUser.id, extraUserParam).subscribe(
        (res: User) => {
          if (res.isOwner || res.isAdmin || res.isSuperAdmin) {
            // if the user is an owner or admin, they can log into any active store that is part of an active zone
            const activeFilter = {
              isActive: { matchMode: "equals", value: true },
              "zone.isActive": { matchMode: "equals", value: true },
            };
            this.stores_lookup$ = this.dbService.lookupSelectOptions("store", "storeName", {
              lookupFilter: JSON.stringify(activeFilter),
              dataKey: null,
              enableFieldName: "isActive",
            });
            this.backOffices_lookup$ = this.dbService.lookupSelectOptions("backOffice", "backOfficeName", {
              lookupFilter: JSON.stringify(activeFilter),
              dataKey: null,
              enableFieldName: "isActive",
            });
          } else {
            //the list of stores that the user can log into is the list of stores that the user has a role on.
            this.stores_lookup$ = of(this.convertUserRolesDataToStores(res.userRoles));
            this.backOffices_lookup$ = of(this.convertUserRolesDataToBackOffices(res.userRoles));
          }
        },
        (err) => {
          this.handleError(err, "Error when getting logged in user.");
        }
      )
    );

    this._isFetchingData = true;
    this.subsList.push(
      this.dbService.getRows("station", "", 0, 1).subscribe(
        (response) => {
          this._isFetchingData = false;
          if (!response || !response.rows.length) {
            // if no stations or stores created navigate to setup screen
            this.router.navigate(["/businessSetup"]);
          } else {
            this.currentStep = LoginSteps.SELECT_LOCATION;
          }
        },
        (err) => {
          this._isFetchingData = false;
          this.handleError(err, "Error getting stations");
        }
      )
    );
  }

  //Given a list of user roles, this method returns an array of SelectItems which contain the stores the user has a role on.
  convertUserRolesDataToStores(userRoles: UserRole[]): SelectItem[] {
    const res: SelectItem[] = [];
    //Add an empty option
    res.push({ label: "", value: null });

    //loop through each user role
    userRoles.forEach((role) => {
      if (role.stores) {
        //for each user role, loop through each store that role applies to.
        role.stores.forEach((store) => {
          //add the store to the result array if it's zone is active and the store itself is active.
          if (store.zone && store.zone.isActive && store.isActive) {
            res.push({ label: store.storeName, value: store });
          }
        });
      }
    });
    return res;
  }

  //Given a list of user roles, this method returns an array of SelectItems which contain the stores the user has a role on.
  convertUserRolesDataToBackOffices(userRoles: UserRole[]): SelectItem[] {
    const res: SelectItem[] = [];
    //Add an empty option
    res.push({ label: "", value: null });

    //loop through each user role
    userRoles.forEach((role) => {
      if (role.backOffices) {
        //for each user role, loop through each store that role applies to.
        role.backOffices.forEach((backOffice) => {
          //add the store to the result array if it's zone is active and the store itself is active.
          if (backOffice.zone && backOffice.zone.isActive && backOffice.isActive) {
            res.push({ label: backOffice.backOfficeName, value: backOffice });
          }
        });
      }
    });
    return res;
  }

  createForm() {
    this.setCustomFields();
    Object.keys(this._validation).forEach((key) => {
      this._mapFormControls[key] = ["", this._validation[key]];
    });

    this._myForm = this.fb.group(this._mapFormControls);
    this.subsList.push(
      this._myForm.valueChanges.subscribe((newValue) => {
        this._loginErrors = null;
      })
    );

    let _locationType = null;
    let _locationStoreId = null;
    let _locationBackOfficeId = null;
    if (this.appSettingsService.getPersistentStoreId()) {
      _locationType = LocationTypes.STORE;
      _locationStoreId = this.appSettingsService.getPersistentStoreId();
    } else if (this.appSettingsService.getPersistentBackOfficeId()) {
      _locationType = LocationTypes.BACK_OFFICE;
      _locationBackOfficeId = this.appSettingsService.getPersistentBackOfficeId();
    } else {
      _locationType = LocationTypes.ADMIN_MODE;
    }

    this.storeSelectionForm = this.fb.group({
      locationType: this.fb.control(_locationType, Validators.required),
      selectedBackOffice: this.fb.control(_locationBackOfficeId),
      selectedStore: this.fb.control(null),
      selectedStation: this.fb.control(null),
    });

    this.addressSelectionForm = this.fb.group({
      accountAddress: this.fb.control(this.appSettingsService.getAccountName(), [CommonValidators.urlAddressSegment()]),
    });
    this.subsList.push(
      this.addressSelectionForm.valueChanges.subscribe((newValue) => {
        this._loginErrors = null;
      })
    );
    this.addressSelectionForm.markAsDirty();

    const selectedBackOfficeCtrl = this.storeSelectionForm.get("selectedBackOffice");
    const selectedStoreCtrl = this.storeSelectionForm.get("selectedStore");
    const selectedStationCtrl = this.storeSelectionForm.get("selectedStation");
    const locationTypeCtrl = this.storeSelectionForm.get("locationType");

    const updateValidation = (locationType) => {
      switch (locationType) {
        // if type is Backoffice make BackOffice dropdown required and made store and station optional
        case LocationTypes.BACK_OFFICE:
          selectedStoreCtrl.clearValidators();
          selectedStationCtrl.clearValidators();
          selectedBackOfficeCtrl.setValidators(Validators.required);
          break;
        // if type is Store make Backoffice optional AND store and station required
        case LocationTypes.STORE:
          selectedBackOfficeCtrl.clearValidators();
          selectedStoreCtrl.setValidators(Validators.required);
          selectedStationCtrl.setValidators(Validators.required);
          break;

        case LocationTypes.ADMIN_MODE:
          selectedStoreCtrl.clearValidators();
          selectedStationCtrl.clearValidators();
          selectedBackOfficeCtrl.clearValidators();
          break;
      }

      // Don't called valueChanges on input in order to preserve state and improve user experience
      selectedStoreCtrl.updateValueAndValidity({ emitEvent: false });
      selectedStationCtrl.updateValueAndValidity({ emitEvent: false });
      selectedBackOfficeCtrl.updateValueAndValidity({ emitEvent: false });
    };

    this.subsList.push(
      selectedStoreCtrl.valueChanges.subscribe((newStore) => {
        this.onStoreChanged(newStore);
      })
    );

    // Set validators dynamically
    updateValidation(locationTypeCtrl.value);
    this.subsList.push(
      locationTypeCtrl.valueChanges.subscribe((newType) => {
        updateValidation(newType);
      })
    );
  }

  async switchStepOnLoginState(): Promise<void> {
    if (this.auth.isLoggedIn()) {
      this.loggedInUser = await this.userService.getLoggedinUser().toPromise();
      // this.locationTypeCtrl.setValue(this.adminModeEnabled ? LocationTypes.ADMIN_MODE : LocationTypes.STORE);
      this.onChangeToLoginTypeScreen();
    } else {
      this.currentStep = LoginSteps.SELECT_STORE_ADDRESS;
    }
  }

  get adminModeEnabled() {
    return (
      this.loggedInUser && (this.loggedInUser.isAdmin || this.loggedInUser.isOwner || this.loggedInUser.isSuperAdmin)
    );
  }

  ngOnInit(): void {
    this.switchStepOnLoginState();

    this.backOffices_lookup$ = of([]);
    this.stores_lookup$ = of([]); // stations need to know first store id in order to build observable
    this.stations_lookup$ = of([]); // stations need to know first store id in order to build observable

    if (this.auth.isLoggedIn()) {
      this.initLookups();
    }
    this.copyrightYear = new Date().getFullYear();
    if (!window.isOnSamsungKiosk) {
      void this.auth.checkForUpdates("/assets/appStats.json", () => {
        location.reload();
      });
    }
  }

  onLogout(): Promise<void> {
    return this.auth.logout().then(() => {
      this.loggedInUser = null;
      this.currentStep = LoginSteps.SELECT_STORE_ADDRESS;
      this._myForm.reset();
      this.ngOnDestroy();
      this.backOffices_lookup$ = of([]);
      this.stores_lookup$ = of([]);
      this.stations_lookup$ = of([]);
      this.storeSelectionForm.get("selectedStore").setValue(null);
      this.storeSelectionForm.get("selectedBackOffice").setValue(null);
      this.storeSelectionForm.get("selectedStation").setValue(null);
      this.storeSelectionForm.get("locationType").setValue(LocationTypes.ADMIN_MODE);
    });
  }

  onGetStartedPressed() {
    this._getStartedClicked = true;
    let store: Store = null;
    let station: Station = null;
    let backOffice: BackOffice = null;
    let zone: Zone = null;
    const loginType = LocationTypeToString(this.locationTypeCtrl.value);

    // Prefetch business details
    this.subsList.push(this.appSettingsService.getBusinessDetails().subscribe());

    const fnStoreSettings = (cashout) => {
      this.subsList.push(
        this.auth.fetchNewToken(store.id, station?.id || 0, backOffice?.id || 0).subscribe({
          next: (resp) => {
            if (station?.id) {
              station.loggedInUserId = this.loggedInUser.id;
            }
            this.appSettingsService.initStorage(
              zone,
              store,
              station,
              backOffice,
              this.loggedInUser,
              cashout,
              loginType
            );
            this.auth.setLoginCompleted(true);
            // this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || this.auth.redirectUrl || '/dashboard');
            this.router.navigateByUrl("/dashboard");
            this._getStartedClicked = false;
          },
          error: (error) => {
            // Display error message on screen
            this._loginErrors = {
              message: "Cannot complete authentication. Please try again",
            };
            console.warn("Token errors", error);
            this._getStartedClicked = false;
          },
        })
      );
    };

    switch (this.locationTypeCtrl.value) {
      case LocationTypes.STORE:
        store = this.storeSelectionForm.get("selectedStore").value;
        station = this.storeSelectionForm.get("selectedStation").value;
        zone = store.zone;
        if (station.loggedInUserId && station.loggedInUserId !== this.loggedInUser.id) {
          this.confirmationService.confirm({
            message:
              "This station is still logged in on a different screen. By logging in here, this station will be logged out of all other screens. Do you still want to proceed?",
            rejectButtonStyleClass: "p-button-link",
            accept: () => {
              // Logging out other user is handled on server side
              this.subsList.push(
                this.cashoutService.searchOpenCashout$(store.id, station.id).subscribe((cashout) => {
                  fnStoreSettings(cashout);
                })
              );
            },
            reject: () => {
              this._getStartedClicked = false;
              return;
            },
          });
        } else {
          // If store was selected try to find Cashout
          this.subsList.push(
            this.cashoutService.searchOpenCashout$(store.id, station.id).subscribe((cashout) => {
              fnStoreSettings(cashout);
            })
          );
        }

        break;

      case LocationTypes.BACK_OFFICE:
        backOffice = this.storeSelectionForm.get("selectedBackOffice").value;
        zone = backOffice.zone;
        store = new Store();
        store.zone = zone;
        fnStoreSettings(null); // no cashout when Backoffice is selected
        break;

      case LocationTypes.ADMIN_MODE:
        backOffice = new BackOffice();
        zone = new Zone();
        store = new Store();
        store.zone = zone;
        fnStoreSettings(null);
        break;
    }
  }

  onBackOfficeTypeStoreSelected() {
    this.storeSelectionForm.controls["locationType"].setValue(LocationTypes.BACK_OFFICE);
  }

  onLocationTypeStoreSelected() {
    this.storeSelectionForm.controls["locationType"].setValue(LocationTypes.STORE);
  }

  onStoreChanged(store: Store) {
    this.storeSelectionForm.get("selectedStation").reset(null);
    if (!store) {
      this.stations_lookup$ = of([]);
    } else {
      this._isFetchingData = true;
      this.stations_lookup$ = this.dbService
        .getRows(
          "station",
          JSON.stringify({
            storeId: { value: store.id, matchMode: "equals" },
          }),
          0,
          1000
        )
        .pipe(
          map(
            (res: any) => <SelectItem[]>res.rows.map((station: Station) => {
                this._isFetchingData = false;
                return { label: station.stationName, value: station };
              })
          )
        );
    }
  }

  initLookups() {
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    // we need to get access to the whole store and backOffice object, not only ids
    // this.backOffices_lookup$ = this.dbService.lookupSelectOptions("backOffice", "backOfficeName", {
    //   lookupFilter: JSON.stringify(activeFilter),
    //   dataKey: null,
    //   enableFieldName: "isActive",
    // });

    // this.stores_lookup$ = this.dbService.getRows('store').pipe(
    //   map(res => <SelectItem[]>res.rows.map((store:Store) => {
    //     return { label: store.storeName, value: store }
    //   }))
    // );
  }

  goPreviousStep() {
    this.currentStep -= 1;
    this._loginErrors = null;
    this._myForm.reset();
  }

  onLogin(_force = false) {
    this._signInClicked = true;
    this._object = this.prepareSaveObject(); // TODO: I think it should be chanegd? we shouldnt save user login
    // send this._object data to server for Authentication
    this.progressBar = this.blockUIService.showProgressBar(this.dialogService, false);
    this.subsList.push(
      this.auth.login(this._object, this.appSettingsService.getAccountName(), _force).subscribe(
        (data) => {
          this.switchStepOnLoginState();
          this.initLookups();
          // this.router.navigate([this.route.snapshot.queryParams['returnUrl'] || '/']);
          this._signInClicked = false;
          this.idleService.reset();
          this.progressBar.close();
        },
        (err) => {
          if (err.error.errors === "ALREADY_LOGGED_IN") {
            this.confirmationService.confirm({
              message:
                "This user is still logged in on a different screen. By logging in here, you will be logged out of all other screens. Do you still want to proceed?",
              rejectButtonStyleClass: "p-button-link",
              accept: () => {
                this.onLogin(true);
                //Actual logic to perform a confirmation
              },
            });
          } else {
            this.handleError(err, "Error when signing in.");
          }
          // this.messageService.add(this.alertService.getErrorMessage(error, 'Error', 'Try again'));
          this._signInClicked = false;
          this.progressBar.close();
        }
      )
    );

    // this.auth.login(this._object);
  }

  handleError(err, summary: string) {
    if (err instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      this.messageService.add(
        this.alertService.getErrorMessage(
          null,
          summary,
          `An unexpected error has occurred. Please try again.<br>Details: ${err.message}`
        )
      );
    } else if (err instanceof HttpErrorResponse) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this._loginErrors = err.error;
      this.passwordFieldComponent.focusAndSelectInput();
    }
  }

  prepareSaveObject(): any {
    const formModel = this._myForm.value;

    const saveObject = formModel;

    return saveObject;
  }

  setCustomFields() {
    this._mapFormControls = new Login();
    this._validation = {
      userName: [Validators.required],
      password: [Validators.required],
    };
  }

  onResize(event: any) {
    this.containerSizeFlag = event.target.innerWidth > 968 ? 2 : 1;
  }

  forwardRegisterAccount(event) {
    this.router.navigate(["/user/0"]);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
