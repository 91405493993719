/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { commonEnvironment } from "./environment.common";

export const environment = {
  envName: "dev",
  production: false,
  apiUrl: "https://takuserver-vp2bdxtl3a-uc.a.run.app/api/", // Google Cloud
  common: commonEnvironment,
  takuPayEnv: "test",
  takuPayClientKey: "test_NLY6QP2DNZCW7BI3SNKBTJU6LUWEXBRP",
};

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
