/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "./db.service";
import { Injectable } from "@angular/core";

export enum FileAlias_ImportTemplates {
  INVENTORY = "importInventoryTemplate",
  PERSONAL_ACCOUNT = "importPersonalAccountTemplate",
  COMMERCIAL_ACCOUNT = "importCommercialAccountTemplate",
  COMMERCIAL_ACCOUNT_CONTACTS = "importCommercialContactsTemplate",
  STORE_CREDIT = "importStoreCredit",
  VOUCHER = "importVoucher",
}

@Injectable({
  providedIn: "root",
})
export class FileSystemBackendService extends DBService {
  downloadImportTemplate(fileAlias: FileAlias_ImportTemplates) {
    return this._getTextFile(this.webUrl + "downloadFile/" + fileAlias);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
