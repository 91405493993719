import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { EMPTY, from, Subscription, timer } from "rxjs";
import { KioskSetting } from "src/app/core/settings/store-settings/kiosk-settings/kioskSettings";
import { switchMap } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { environment } from "src/environments/environment";
import { MessageService } from "primeng/api";

declare let b2bapis: any;

@Component({
  selector: "taku-self-checkout-landing-page",
  templateUrl: "./self-checkout-landing-page.component.html",
  styleUrls: ["./self-checkout-landing-page.component.scss"],
})
export class SelfCheckoutLandingPageComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  public storeName: string;
  public storeLogoUrl: string;
  public businessLogoUrl: string;

  startBtnCustomBgColor: string;
  startBtnCustomFontColor: string;
  customBgImageUrl: string;
  displayContents = false;

  /**
   * This info found in the PDF in the ticket for TPT-3657
   * @param url Server url to download package file except domain.
   * @param updateNow Whether to update right now(true) or after the next boot(false)
   */
  private updateKiosk(url: string, updateNow: boolean): void {
    b2bapis.paymentdownload.update(url, updateNow);
  }

  /** Every 30 minutes, check for a Kiosk package update and install it if available */
  private updateTimer = !window.isOnSamsungKiosk
    ? EMPTY
    : timer(0, 1_800_000).pipe(
        switchMap(() =>
          from(
            this.auth.checkForUpdates(
              `https://kiosk.takuretail.app/${environment.envName}appStats.json`,
              (reloadAttemptValue: string) => {
                let message = `Updating kiosk package, ${reloadAttemptValue}`;
                this.auth.writeServerLog(message).subscribe();
                let logLevel: "info" | "error" = "info";
                try {
                  this.messageService.add({
                    severity: "info",
                    summary: "Downloading kiosk update...",
                    detail: `The latest app improvements are being installed.<br/>Version: ${reloadAttemptValue}`,
                    sticky: true,
                  });
                  this.messageService.add({
                    severity: "warn",
                    summary: "Wait for update to finish!",
                    detail: `App will be restarted automatically very soon, which could interrupt any in-process transactions.`,
                    sticky: true,
                  });
                  this.updateKiosk(`/${environment.envName}latest.zip`, true);
                  message = `No error calling for kiosk package update, ${reloadAttemptValue}`;
                } catch (error) {
                  message = "Error trying to update kiosk: " + JSON.stringify(error, Object.getOwnPropertyNames(error));
                  logLevel = "error";
                }
                this.auth.writeServerLog(message, logLevel).subscribe();
              }
            )
          )
        )
      );

  constructor(
    public app: AppComponent,
    private appSettingsService: AppSettingsStorageService,
    private router: Router,
    private messageService: MessageService,
    private auth: AuthService
  ) {
    const store = this.appSettingsService.getStore();
    this.storeName = store.storeName;
    this.storeLogoUrl = store.urlStoreLogoUrl475;
  }

  ngOnInit(): void {
    this.app.onMenuButtonClick();
    this.subsList.push(
      this.appSettingsService.getBusinessDetails().subscribe((businessDetails) => {
        this.businessLogoUrl = businessDetails.urlBusinessDetailBusinessLogoUrl;
      })
    );

    this.subsList.push(
      this.appSettingsService.getKioskStoreSettingDetails().subscribe((kioskSettingsData: KioskSetting) => {
        this.startBtnCustomBgColor = kioskSettingsData.colorTheme;
        this.startBtnCustomFontColor = kioskSettingsData.mainScreenBtnFontColor;
        this.customBgImageUrl =
          kioskSettingsData.urlOpeningScreenImageFileName || "/assets/layout/images/defaultKioskBackground.png";
        setTimeout(() => {
          this.displayContents = true;
        }, 50);
      })
    );
    this.subsList.push(this.updateTimer.subscribe(() => console.debug("Checked for kiosk package update")));
  }

  onTapBtnClicked() {
    this.router.navigateByUrl("/kiosk/selfCheckout/saleDoc/0");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
