/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AfterViewChecked, AfterViewInit, Component, NgZone, OnDestroy, Renderer2, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
// import { ResizedEvent } from 'angular-resize-event';
import { of, Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { LoginComponent } from "./core/users/login/login.component";
import { FormHeaderComponent } from "./forms/form-header/form-header.component";
import { AppSettingsStorageService } from "./shared/app-settings-storage.service";
import { AuthService } from "./shared/services/auth.service";
import { BusinessSetupComponent } from "./wizards/business-setup/business-setup.component";
import { environment } from "src/environments/environment";
import { IntercomCustomDataService } from "./shared/intercom-custom-data.service";
import { MenuService } from "./app.menu.service";
import { Location } from "@angular/common";
import { DBService } from "./shared/services/db.service";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { SelfCheckoutLandingPageComponent } from "./core/document/sale-document/self-checkout-landing-page/self-checkout-landing-page.component";
import { SaleDocSelfCheckoutMainComponent } from "./core/document/sale-document/sale-doc-self-checkout-main/sale-doc-self-checkout-main.component";
import { WebHelpers } from "./utility/WebHelpers";
import { IdleService } from "./idle.service";
import { ServerVersion$ } from "./shared/services/http-interceptors/server-version-interceptor.service";

const VERSION_KEY = "APP_VERSION";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit, OnDestroy, AfterViewChecked, OnInit {
  topbarTheme = "grey";
  menuTheme = "light";
  layoutMode = "light";
  menuMode = "static";
  inlineMenuPosition = "none";
  inputStyle = "outlined";
  ripple = true;
  isRTL = false;

  subsList: Subscription[] = [];
  private ngViewChecks$ = new Subject();
  appVersion = environment.common.APP_VERSION;
  serverVersion = ServerVersion$;
  protected webUrl = environment.apiUrl;

  topbarMenuActive: boolean;

  menuActive: boolean;

  staticMenuDesktopInactive: boolean;

  mobileMenuActive: boolean;

  menuClick: boolean;

  mobileTopbarActive: boolean;

  topbarRightClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: string;

  documentClickListener: () => void;

  configActive: boolean;

  configClick: boolean;

  rightMenuActive: boolean;

  menuHoverActive = false;

  searchClick = false;

  search = false;

  currentInlineMenuKey: string;

  inlineMenuActive: any[] = [];

  inlineMenuClick: boolean;
  // Added for customization
  isLoginScreen = false;
  isKioskScreen = false;
  isSetupScreen = false;

  // custom code in TAKU
  mobileTopBarButtonClick: boolean;
  mobileTopBarPanelClick: boolean;
  loginComponent: LoginComponent;
  // initAppCuesWidget() {
  //   // This is your widget instance.
  //   const widget = AppcuesWidget(Appcues.user());
  //   // JS to bind the widget to an element on your site
  //   widget.init('#appcues-widget', {
  //     // Optionally specify the position of the content relative to the widget icon.
  //     // Possible values: "center" (default; alias "bottom", "bottom-center"), "left" (alias "bottom-left"),
  //     // "right" (alias "bottom-right"), "top" (alias "top-center"), "top-left", "top-right"
  //     position: 'left',
  //     // Optionally add a header and/or footer.
  //     // header: "<h1>Tutorials</h1>",
  //     // footer: "<p>Your footer here</p>"
  //   });
  // }

  constructor(
    public idleService: IdleService,
    private primengConfig: PrimeNGConfig,
    location: Location,
    router: Router,
    public renderer: Renderer2,
    private zone: NgZone,
    private menuService: MenuService,
    public auth: AuthService,
    private appSettingsService: AppSettingsStorageService,
    private dbService: DBService,
    private messageService: MessageService,
    private intercomService: IntercomCustomDataService
  ) {
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subsList.push(
      idleService.idle.onTimeout.subscribe(() => {
        if (location.path().search("kiosk/selfCheckout") == -1 && !idleService.timedOut) {
          idleService.idleState = "";
          idleService.timedOut = true;
          idleService.stop();
          if (this.isLoginScreen && this.loginComponent) {
            void this.loginComponent.onLogout();
          } else {
            void this.auth.logout({ bypassGuards: true });
          }
          this.messageService.add({
            severity: "warn",
            summary: "Logged out due to idleness",
            detail: "Users are auto-logged out after 2 hours of inactivity",
            sticky: true,
          });
        }
      })
    );
    // this.subsList.push(
    //   idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!'));
    // this.subsList.push(
    //   idle.onTimeoutWarning.subscribe((countdown: number) => this.idleState = 'You will time out in ' + countdown + ' seconds!'));
    if (auth.isLoggedIn()) {
      this.idleService.reset();
    }

    this.subsList.push(
      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (
            location.path().search("settings/importer") !== -1 ||
            location.path().search("kiosk/selfCheckout/landingPage") !== -1
          ) {
            idleService.stop();
          } else if (location.path().search("kiosk/selfCheckout/saleDoc") !== -1) {
          } else {
            // sets an idle timeout of 5 seconds, for testing purposes.
            if (environment.production) {
              idleService.idle.setIdle(2 * 60 * 60);
            } else {
              idleService.idle.setIdle(2 * 60 * 60);
            }
            // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
            idleService.idle.setTimeout(30);

            if (auth.isLoggedIn()) {
              idleService.idle.watch();
            }
          }
          //   window.Appcues.page();
          // NOTE: These values should be specific to the current user.
          const _user = this.appSettingsService.getUser();
          if (_user) {
            // window['Appcues'].identify(_user.uuid, { // Unique identifier for current user
            //   name: _user.person.firstName + ' ' + _user.person.lastName, // current user's name
            //   email: _user.email, // Current user's email
            //   // createdAt: _user.create, // Unix timestamp of user signup date
            //   // companyName: "Acme Corp", // Current user’s company
            //   // planType: "Trial", // Current user’s plan type
            //   // planTier: "Standard", // Current user’s plan tier
            //   // role: _user.role // Current user’s role or permissions

            //   // additional recommended user properties:

            //   // team: "Marketing" // or any other way you segment your users
            //   // location: "02141" // a zipcode, state, or country enables location-based targeting
            //   // version: "2.0" // users on different versions may need to see different content
            //   // featureFlag: "beta” // to communicate with users who have different features
            //   // language: "spanish" // for multi-language applications
            //   // renewalDate: "06-16-2019" // to remind users to renew
            // });
            // AppCue's Static Widget script
            // this.initAppCuesWidget();

            if (!window.isOnSamsungKiosk) {
              setTimeout(() => {
                this.subsList.push(
                  this.dbService._getRequest<string>(`${this.webUrl}intercomUserHash`).subscribe(
                    (_userHash: string) => {
                      this.intercomService.getCustomData().subscribe((res) => {
                        window["Intercom"]("boot", {
                          app_id: "qjcbjndy",
                          email: _user.email, // Current user's email
                          user_id: _user.userName,
                          custom_launcher_selector: "#intercom_launcher_link",
                          hide_default_launcher: true,
                          user_hash: _userHash,
                          has_created_25_product: res.hasCreated25Products,
                          has_created_transaction: res.hasMadeTransaction,
                          has_gmb_account: res.hasGmbAccount,
                          has_gmc_account: res.hasGmcAccount,
                          // Website visitor so may not have any user related info
                        });
                      });
                    },
                    (err) => console.log(err)
                  )
                );
              }, 200);
            }
          }
        }
      })
    );
  }

  getToastIconClass(severity) {
    switch (severity) {
      case "success":
        return "pi pi-check-circle";
      case "info":
        return "pi pi-info-circle";
      case "warn":
        return "pi pi-exclamation-circle";
      case "error":
        return "pi pi-times-circle";
      default:
        return "";
    }
  }

  ngOnInit(): void {
    this.menuActive = this.isStatic() && !this.isMobile();
    this.primengConfig.ripple = true;
  }

  /** Compares previously stored version vs current, and creates notification if app has just upgraded */
  private checkIfFrontendVersionUpgraded() {
    const previousVersion = localStorage.getItem(VERSION_KEY);
    if (previousVersion && previousVersion !== environment.common.APP_VERSION) {
      this.messageService.add({
        severity: "info",
        summary: "App Version Upgrade!",
        detail: `New version: ${environment.common.APP_VERSION}<br/>Old version:  ${previousVersion}`,
        sticky: true,
      });
    }
    localStorage.setItem(VERSION_KEY, environment.common.APP_VERSION);
  }

  ngAfterViewChecked(): void {
    this.zone.runOutsideAngular(() => {
      this.ngViewChecks$.next(null);
    });
  }

  /* [Nazy] Respond after Angular initializes the component's views and child views.
   * Called once after the first ngAfterContentChecked(). A component-only hook. */
  ngAfterViewInit(): void {
    this.checkIfFrontendVersionUpgraded();
    // if (this.layoutContainerViewChild) this.layoutContainer = this.layoutContainerViewChild.nativeElement as HTMLDivElement;
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen("body", "click", () => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.mobileTopBarButtonClick && !this.mobileTopBarPanelClick) {
        this.mobileTopbarActive = false;
      }

      if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
        this.menuService.reset();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      if (!this.menuClick) {
        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.menuHoverActive = false;
        this.unblockBodyScroll();
      }

      if (!this.searchClick) {
        this.search = false;
      }

      if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
        this.inlineMenuActive[this.currentInlineMenuKey] = false;
      }

      this.inlineMenuClick = false;
      this.searchClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.mobileTopBarButtonClick = false;
      this.mobileTopBarPanelClick = false;
      this.topbarRightClick = false;
      this.menuClick = false;
    });

    const htmlEl = document.getElementsByTagName("html")[0];
    // Add class to HTML tag when we open an overlay
    this.subsList.push(
      this.ngViewChecks$.pipe(debounceTime(250)).subscribe(() => {
        const className = "p-overflow-hidden";
        const hasOverlay = !!document.querySelector(`body.${className}`);
        if (hasOverlay) {
          htmlEl.classList.add(className);
        } else htmlEl.classList.remove(className);
      })
    );

    this.subsList.push(
      this.ngViewChecks$.pipe(debounceTime(250)).subscribe(() => {
        // Hack to prevent autoclosing of primeng components' overlay attached to the body
        document.body
          .querySelectorAll(
            ":scope > .p-component:not(.p-dropdown-panel):not(.p-multiselect-panel):not(.p-dialog):not(.p-overlaypanel):not(.p-overlaypanel):not(.p-autocomplete-panel)"
          )
          .forEach((el) => {
            const listener = this.renderer.listen(el, "click", (event) => {
              listener(); // prevent resouces leaking over time
              event.stopPropagation();
            });
          });
      })
    );
  }

  onActivate(componentRef: Event) {
    this.isLoginScreen = componentRef instanceof LoginComponent;
    this.isSetupScreen = componentRef instanceof BusinessSetupComponent;
    this.isKioskScreen =
      componentRef instanceof SelfCheckoutLandingPageComponent ||
      componentRef instanceof SaleDocSelfCheckoutMainComponent;

    if (this.isKioskScreen && !window.isOnSamsungKiosk) {
      // Document: https://developers.intercom.com/installing-intercom/docs/intercom-javascript
      // This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
      (<any>window).Intercom("hide");

      // For Handley's testing case: If I close and reopen incognito/private web browser and login to TAKU, Messenger Launcher does not auto hide.
      const domNode = document.getElementById("intercom-container");
      if (domNode) domNode.style.display = "none";
    }

    if (componentRef instanceof LoginComponent) {
      this.loginComponent = componentRef;
    }
    // try to scroll to the top when loading new components
    window.scrollTo(0, 0);
  }

  onContentContainerResized(event) {
    FormHeaderComponent.resizeBar(event.target.innerWidth);
  }

  onMenuButtonClick(event?: Event) {
    this.menuActive = !this.menuActive;
    this.menuClick = true;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
      if (this.mobileMenuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    if (event) event.preventDefault();
  }

  onTopbarMobileButtonClick(event) {
    //custom code in TAKU: 1 line below
    this.mobileTopBarButtonClick = true;
    this.mobileTopbarActive = !this.mobileTopbarActive;
    event.preventDefault();
  }

  //custom code in TAKU
  onMobilePanelClick(event) {
    this.mobileTopBarPanelClick = true;
  }

  onRightMenuButtonClick(event) {
    this.rightMenuActive = !this.rightMenuActive;
    event.preventDefault();
  }

  onMenuClick($event) {
    this.menuClick = true;
    if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }
  }

  onSearchKeydown(event) {
    if (event.keyCode === 27) {
      this.search = false;
    }
  }
  onInlineMenuClick(event, key) {
    if (key !== this.currentInlineMenuKey) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }

    this.inlineMenuActive[key] = !this.inlineMenuActive[key];
    this.currentInlineMenuKey = key;
    this.inlineMenuClick = true;
  }

  onTopbarItemClick(event: Event, item: any) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    if (item === "search") {
      this.search = !this.search;
      this.searchClick = !this.searchClick;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event: Event) {
    event.preventDefault();
  }

  onRTLChange(event) {
    this.isRTL = event.checked;
  }

  onRippleChange(event) {
    this.ripple = event.checked;
    this.primengConfig.ripple = event.checked;
  }

  onConfigClick() {
    this.configClick = true;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isNotMobile() {
    return !WebHelpers.isMobileScreen();
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isOverlay() {
    return this.menuMode === "overlay";
  }

  isStatic() {
    return this.menuMode === "static";
  }

  isHorizontal() {
    return this.menuMode === "horizontal";
  }

  isSlim() {
    return this.menuMode === "slim";
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
    }
  }
  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  get showSidebarMenu() {
    return true;
    // return this.appSettingsService.getStoreId();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
