/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, OnInit, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "primeng/dynamicdialog";
import { BackendGMBService } from "../integration-settings/google-my-business/BackendGMBService";
import { BackendGMCService } from "../integration-settings/google-shopping/BackendGMCService";
import { GMBConsentConfirmationComponent } from "../integration-settings/shared/gmb-consent-confirmation/gmb-consent-confirmation.component";
import { StoreConnectedConfirmationComponent } from "../integration-settings/shared/store-connected-confirmation/store-connected-confirmation.component";
import { SettingCardSection } from "../settings-cards/setting-cards.component";
import { GoogleShoppingConsentConfirmationComponent } from "../integration-settings/shared/google-shopping-consent-confirmation/google-shopping-consent-confirmation.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { ChannelType } from "../../sale-channel/sale-channel";
import { DBService } from "src/app/shared/services/db.service";

@Component({
  selector: "taku-integration-settings-cards",
  templateUrl: "./integration-settings-cards.component.html",
  providers: [DialogService],
  styleUrls: ["./integration-settings-cards.component.scss"],
})
export class IntegrationSettingsCardsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  isGoogleShoppingVisible = false;
  isGMBVisible = false;

  isTakuPayVisible = true;
  isTakuPayEnabled = true;
  isTAKUeCommerceVisible = false;
  isShopifyVisible = false;
  isMonerisVisible = true;
  isMonerisEnabled = true;
  isWooCommerceVisible = false;
  isFacebookVisible = false;
  isInstagramVisible = false;
  isAmazonVisible = false;

  isGMBEnabled = false;
  isGoogleShoppingEnabled = false;

  isTAKUeCommerceEnabled = false;
  isShopifyEnabled = false;
  isWooCommerceEnabled = false;
  isFacebookEnabled = false;
  isInstagramEnabled = false;
  isAmazonEnabled = false;

  _googleShoppingAction = new EventEmitter<any>();
  _googleGMBAction = new EventEmitter<any>();

  _paymentAction = new EventEmitter<any>();
  _saleChannelAction = new EventEmitter<any>();

  _filterText: string;

  pageTitle = "Browse Apps";
  pageIconStyleClass = "ui-icon-apps";
  // pageTitle: string;
  // pageIconStyleClass: string;

  cardsInfo: SettingCardSection[] = [];

  storeId: number;
  zoneId: number;
  defaultStockId: number;

  // showOnlyConnected: boolean;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    public _route: ActivatedRoute,
    private appSettingsService: AppSettingsStorageService,
    private dbService: DBService,
    private gmbService: BackendGMBService,
    private gmcService: BackendGMCService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isGMBVisible = this.authService.hasVisiblePermit("Apps_Menu_Google_My_Business_(GMB)_Card");
    this.isGoogleShoppingVisible = this.authService.hasVisiblePermit("Apps_Menu_Google_Shopping_Card");
    this.isTAKUeCommerceVisible = this.authService.hasVisiblePermit("Apps_Menu_Taku_eCommerce");
    this.isTakuPayVisible = this.authService.hasVisiblePermit("Apps_Menu_Taku_Pay");
    this.isMonerisVisible = this.authService.hasVisiblePermit("Apps_Menu_Moneris");
    this.isWooCommerceVisible = this.authService.hasVisiblePermit("SalesChannels_Menu_WooCommerce");
    this.isFacebookVisible = this.authService.hasVisiblePermit("SalesChannels_Menu_Facebook");
    this.isInstagramVisible = this.authService.hasVisiblePermit("SalesChannels_Menu_Instagram");
    this.isAmazonVisible = this.authService.hasVisiblePermit("SalesChannels_Menu_Amazon");
    // this.isShopifyVisible = this.authService.hasVisiblePermit('SalesChannels_Menu_Shopify');

    // this.isGoogleShoppingEnabled = this.authService.hasEnablePermit("Apps_Menu_Google_Shopping_Card");
    // this.isGMBEnabled = this.authService.hasEnablePermit("Apps_Menu_Google_My_Business_(GMB)_Card");
    this.isTakuPayEnabled = this.authService.hasEnablePermit("Apps_Menu_Taku_Pay");
    this.isTAKUeCommerceEnabled = this.authService.hasEnablePermit("Apps_Menu_Taku_eCommerce");
    this.isMonerisEnabled = this.authService.hasEnablePermit("Apps_Menu_Moneris");
    this.isWooCommerceVisible = this.authService.hasEnablePermit("SalesChannels_Menu_WooCommerce");
    this.isFacebookVisible = this.authService.hasEnablePermit("SalesChannels_Menu_Facebook");
    this.isInstagramVisible = this.authService.hasEnablePermit("SalesChannels_Menu_Instagram");
    this.isAmazonVisible = this.authService.hasEnablePermit("SalesChannels_Menu_Amazon");
    // this.isShopifyVisible = this.authService.hasEnablePermit('SalesChannels_Menu_Shopify');

    this.initializeCards();

    this.storeId = this.appSettingsService.getStoreId();
    this.zoneId = this.appSettingsService.getZoneId();
    this.defaultStockId = this.storeId ? this.appSettingsService.getStore().stockId : null;

    // this.subsList.push(this._route.data.subscribe((routeData) => {
    //   this.showOnlyConnected = routeData.showOnlyConnected;
    //   this.pageTitle = routeData.pageTitle;
    // }));

    // Open GMB Consent Screen Dialog
    this.subsList.push(
      this._googleGMBAction.subscribe(() => {
        if (this.isGMBEnabled && this.isGMBVisible) {
          this.onGMBOpened();
        }
      })
    );

    // Open Google Shopping Consent Screen Dialog
    this.subsList.push(
      this._googleShoppingAction.subscribe(() => {
        if (this.isGoogleShoppingEnabled && this.isGoogleShoppingVisible) {
          this.onGoogleShoppingOpened();
        }
      })
    );

    // Opening a Sales Channel
    this.subsList.push(
      this._saleChannelAction.subscribe((channelType: ChannelType) => {
        this.onSaleChannelOpened(channelType);
      })
    );

    // Open Payment
    this.subsList.push(this._paymentAction.subscribe((paymentType) => {}));
  }

  private initializeCards() {
    this.cardsInfo = [
      {
        label: "Sales Channels",
        cards: [
          {
            channelType: ChannelType.takuecommerce,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-shopping-bag-solid",
            cardTitle: "TAKU eCommerce",
            cardDesc: ["Manage this app in your TAKU eCommerce Sales Channel"],
            isEnabled: this.isTAKUeCommerceEnabled,
            isVisible: this.isTAKUeCommerceVisible,
            isConnected: true,
          },
          {
            channelType: ChannelType.shopify,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-shopify-brands",
            cardTitle: "Shopify",
            cardDesc: ["Manage this app in your Shopify Sales Channel"],
            isVisible: this.isShopifyVisible,
            isEnabled: this.isShopifyEnabled,
            isConnected: false,
          },
          {
            channelType: ChannelType.woocommerce,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-wordpress-brands",
            cardTitle: "WooCommerce",
            cardDesc: ["Manage this app in your WooCommerce Sales Channel"],
            isVisible: this.isWooCommerceVisible,
            isEnabled: this.isWooCommerceEnabled,
            isConnected: false,
          },
          {
            channelType: ChannelType.facebook,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-facebook-brands",
            cardTitle: "Facebook",
            cardDesc: ["Manage this app in your Facebook Sales Channel"],
            isVisible: this.isFacebookVisible,
            isEnabled: this.isFacebookEnabled,
            isConnected: false,
          },
          {
            channelType: ChannelType.instagram,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-instagram-square-brands",
            cardTitle: "Instagram",
            cardDesc: ["Manage this app in your Instagram Sales Channel"],
            isVisible: this.isInstagramVisible,
            isEnabled: this.isInstagramEnabled,
            isConnected: false,
          },
          {
            channelType: ChannelType.amazon,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-amazon-brands",
            cardTitle: "Amazon",
            cardDesc: ["Manage this app in your Amazon Sales Channel"],
            isVisible: this.isAmazonVisible,
            isEnabled: this.isAmazonEnabled,
            isConnected: false,
          },
        ],
      },
      {
        label: "Marketing",
        cards: [
          {
            // routePath: ["/integrations/gmb/main"],
            openAction: this._googleGMBAction,
            iconStyleClass: "takuicon-gmb",
            cardTitle: "Google My Business (GMB)",
            cardDesc: ["Connect your Google My Business Account"],
            isVisible: this.isGMBVisible,
            isEnabled: this.isGMBEnabled,
            isConnected: true,
          },
          {
            // routePath: ["/integrations/google-shopping/main"],
            openAction: this._googleShoppingAction,
            iconStyleClass: "takuicon-google-integration",
            cardTitle: "Google Shopping",
            cardDesc: ["Connect your Google Merchant Center Account"],
            isVisible: this.isGoogleShoppingVisible,
            isEnabled: this.isGoogleShoppingEnabled,
            isConnected: true,
          },
        ],
      },
      {
        label: "Payments",
        cards: [
          {
            // routePath: ['/integrations/my-apps/takupay/'+this.storeId],
            routePath: ["/integrations/my-apps/takupay/"],
            iconStyleClass: "pi pi-credit-card",
            cardTitle: "TAKU Pay",
            cardDesc: ["Accept payments in Canada/US", "Connect your Bank Account", "Pair Terminals"],
            isConnected: false,
            isEnabled: this.isTakuPayEnabled,
            isVisible: this.isTakuPayVisible,
          },
          {
            routePath: ["/integrations/my-apps/payment-gateways/list/Moneris/" + this.storeId],
            iconStyleClass: "pi pi-credit-card",
            cardTitle: "Moneris",
            cardDesc: ["Accept payments in Canada", "Connect your Moneris Account", "Pair Terminals"],
            isConnected: true,
            isEnabled: this.isMonerisEnabled,
            isVisible: this.isMonerisVisible,
          },
          // {
          //   routePath: ['/integrations/my-apps/payment-gateways/list/eConduit/'+this.storeId],
          //   iconStyleClass: 'pi pi-credit-card',
          //   cardTitle: 'PayRoc',
          //   isEnabled: false,
          //   cardDesc: [
          //     'Accept payments in the US',
          //     'Connect your PayRoc Account',
          //     'Pair Terminals'
          //   ],
          //   isConnected: false
          // }
        ],
      },
    ];
  }

  private onSaleChannelOpened(channelType: ChannelType) {
    // const queryObject = {
    //   channelType: {
    //     value: channelType,
    //     matchMode: 'equals'
    //   },
    //   isConnected: {
    //     value: true,
    //     matchMode: 'equals'
    //   }
    // };
    // this.dbService.getRows('saleChannel', JSON.stringify(queryObject)).subscribe((saleChannels) => {
    //   console.log(saleChannels);
    // })
    switch (channelType) {
      case ChannelType.takuecommerce:
        const landingPageRoute = ["/integrations/my-apps/sale-channel/settings", this.storeId];
        this.router.navigate(landingPageRoute, {
          queryParams: {
            title: "TAKU eCommerce",
            queryExtraParams: JSON.stringify({ stockId: this.defaultStockId, zoneId: this.zoneId }),
          },
        });
        break;
      case ChannelType.shopify:
        break;
      case ChannelType.woocommerce:
        break;
      case ChannelType.facebook:
        break;
      case ChannelType.instagram:
        break;
      case ChannelType.amazon:
        break;
    }
  }

  private onShopifyOpened() {
    // const landingPageRoute = ['/integrations/sale-channel/settings', this.storeId];
    // this.router.navigate(landingPageRoute,
    //   { queryParams: { title: 'Shopify', queryExtraParams: JSON.stringify({ stockId: this.defaultStockId, zoneId: this.zoneId }) } }
    // );
    console.log("opening Shopify sales channel!");
  }

  private onWooCommerceOpened() {
    // const landingPageRoute = ['/integrations/sale-channel/settings', this.storeId];
    // this.router.navigate(landingPageRoute,
    //   { queryParams: { title: 'Shopify', queryExtraParams: JSON.stringify({ stockId: this.defaultStockId, zoneId: this.zoneId }) } }
    // );
    console.log("opening WooCommerce sales channel!");
  }

  private onFacebookOpened() {
    console.log("opening Facebook sales channel!");
  }

  private onInstagramOpened() {
    console.log("opening Instagram sales channel!");
  }

  private onAmazonOpened() {
    console.log("opening Amazon sales channel!");
  }

  private onGMBOpened() {
    const landingPageRoute = ["/integrations/my-apps/gmb/main"];
    this.subsList.push(
      this.gmbService.isIntegrationEnabled().subscribe((isEnabled) => {
        if (isEnabled) {
          // If enable navigate right away
          this.router.navigate(landingPageRoute);
        } else {
          const dialogRef = this.dialogService.open(GMBConsentConfirmationComponent, {
            contentStyle: {
              minWidth: "480pt",
              maxWidth: "540pt",
            },
          });

          this.subsList.push(
            dialogRef.onClose.subscribe((result) => {
              if (result && result.success) {
                this.showStoreConnectedDialog(landingPageRoute);
              }
            })
          );
        }
      })
    );
  }

  private onGoogleShoppingOpened() {
    const landingPageRoute = ["/integrations/my-apps/google-shopping/products"];
    this.subsList.push(
      this.gmcService.isIntegrationEnabled().subscribe((isEnabled) => {
        if (isEnabled) {
          // If enable navigate right away
          this.router.navigate(landingPageRoute);
        } else {
          const dialogRef = this.dialogService.open(GoogleShoppingConsentConfirmationComponent, {
            contentStyle: {
              minWidth: "480pt",
              maxWidth: "540pt",
            },
          });

          this.subsList.push(
            dialogRef.onClose.subscribe((result) => {
              if (result && result.success) {
                this.showStoreConnectedDialog(landingPageRoute);
              }
            })
          );
        }
      })
    );
  }

  private showStoreConnectedDialog(routePath: string[]) {
    const dialogRef = this.dialogService.open(StoreConnectedConfirmationComponent, {
      style: {
        // maxWidth: '50%',
      },
    });

    this.subsList.push(
      dialogRef.onClose.subscribe((result) => {
        this.router.navigate(routePath);
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
