/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "./services/db.service";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class IntercomCustomDataService {
  constructor(private dbService: DBService) {}
  //This function checks if this business has created 25 products
  hasCreated25Products(): Observable<boolean> {
    return this.dbService.getRows("inventory", "", 0, 0).pipe(
      map((result: any) => {
        return result.count >= 25;
      })
    );
  }
  //Check if any transactions have been made.
  hasMadeTransaction(): Observable<boolean> {
    return this.dbService.getRows("saleDoc", "", 0, 0).pipe(
      map((result: any) => {
        return result.count > 0;
      })
    );
  }

  //Check if it has atleast 1 GMB Account.

  hasGMBAccount(): Observable<boolean> {
    const _filter = {
      isActive: {
        value: true,
        matchMode: "equals",
      },
    };
    return this.dbService.getRows("gmbGoogleAccount", JSON.stringify(_filter), 0, 0).pipe(
      map((result: any) => {
        return result.count > 0;
      })
    );
  }
  //Check if it has atleast 1 GMC Account.
  hasGMCAccount(): Observable<boolean> {
    const _filter = {
      isActive: {
        value: true,
        matchMode: "equals",
      },
    };
    return this.dbService.getRows("gmcGoogleAccount", JSON.stringify(_filter), 0, 0).pipe(
      map((result: any) => {
        return result.count > 0;
      })
    );
  }

  getCustomData(): Observable<any> {
    return forkJoin({
      hasCreated25Products: this.hasCreated25Products(),
      hasMadeTransaction: this.hasMadeTransaction(),
      // hasGmbAccount: this.hasGMBAccount(),
      // hasGmcAccount: this.hasGMCAccount(),
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
