/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormGroup } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AuthService } from "src/app/shared/services/auth.service";
import { FileSystemBackendService } from "src/app/shared/services/FileSystemBackend.service";
import { InventoryImage } from "../inventory-image/inventory-image";
import { InventoryImageComponent } from "../inventory-image/inventory-image.component";
import { Subscription } from "rxjs";
import { DBService } from "src/app/shared/services/db.service";
import { environment } from "src/environments/environment";
import { FormDialogInputComponent } from "src/app/forms/form-dialog-input/form-dialog-input.component";
import { MessageService } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { WebHelpers } from "src/app/utility/WebHelpers";

@Component({
  selector: "taku-inventory-gallery-dialog",
  templateUrl: "./inventory-gallery-dialog.component.html",
  styleUrls: ["./inventory-gallery-dialog.component.scss"],
  providers: [DialogService],
})
export class InventoryGalleryDialogComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  displayGalleryDialog = false;
  _activePicture: AbstractControl;
  imagesFormArray: UntypedFormArray;
  _isUploading = false;
  @ViewChild("fileUploader") _fileUploader: FileUpload;
  pinnedChangedSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    protected dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fileSystemBackendService: FileSystemBackendService,
    public dbService: DBService,
    private httpClient: HttpClient,
    private messageService: MessageService
  ) {
    this.imagesFormArray = config.data.imagesFormArray || this.fb.array([]);

    this.subsList.push(
      this.ref.onClose.subscribe(() => {
        if (this.pinnedChangedSubscription) this.pinnedChangedSubscription.unsubscribe();
      })
    );
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  myUploader(event: { files: any[] }) {
    // get signedUrl
    event.files.map((_file) => {
      if (_file.size <= 2 * 1024 * 1024) {
        this._isUploading = true;
        this.subsList.push(
          this.dbService._getRequest<any>(environment.apiUrl + "uploadURL/" + _file.name).subscribe(
            (response: any) => {
              this.subsList.push(
                this.httpClient.put(response.url, _file).subscribe(
                  () => {
                    this.onAddNewPicture(response);
                    this.onPictureSelected(0); // auto selects the recently added/uploaded picture
                    this.imagesFormArray.markAsDirty();
                    this._isUploading = false;
                  },
                  (err) => {}
                )
              );
            },
            (err) => {}
          )
        );
      } else {
        this.messageService.add({
          severity: "warn",
          summary: "Warning",
          detail: "Maximum upload size of 2MB per file",
        });
      }
    });
    this._fileUploader.clear();
  }

  addFromURL() {
    const dialogRef = this.dialogService.open(FormDialogInputComponent, {
      showHeader: true,
      header: "Add from URL",
      data: {
        caption: "Image Source URL",
        message:
          "By associating the image URL above, you accept our terms of service and confirm that you have the right to use this image publicly.",
        acceptLabel: "Save URL",
        // rejectVisible: false,
      },
    });
    this.subsList.push(
      dialogRef.onClose.subscribe((result) => {
        // get signedUrl
        if (result) {
          this._isUploading = true;
          this.subsList.push(
            this.dbService._postRequest<any>(environment.apiUrl + "uploadURL", { url: result }).subscribe({
              next: (response: any) => {
                this.onAddNewPicture(response);
                this.onPictureSelected(0); // auto selects the recently added/uploaded picture
                this.imagesFormArray.markAsDirty();
              },
              error: (err) => {
                this.messageService.add({
                  severity: "error",
                  summary: "Media Upload Failed",
                  detail:
                    "Media failed to process because the image could not be downloaded automatically. Alternatively, you can download it and upload it manually.",
                  life: 6000,
                });
              },
              complete: () => {
                this._isUploading = false;
              },
            })
          );
        }
        // (response:any) => {
        //   if (response.error) {
        //     this.messageService.add({
        //       severity: 'error',
        //       summary: 'Media Upload Failed',
        //       detail: 'Media failed to process because the image could not be downloaded automatically. Alternatively, you can download it and upload it manually.',
        //       life: 6000
        //     })
        //   } else {
        //     this.onAddNewPicture(response);
        //     this.onPictureSelected(0); // auto selects the recently added/uploaded picture
        //     this.imagesFormArray.markAsDirty();
        //     // this.progressBarValue = 100;
        //   }
        //   this._isUploading = false;
        // },
        // (err) => { },
      })
    );
  }

  openGalleryDialog(e: MouseEvent) {
    // Prevents strange bug that is triggering click event on pressign enter,
    // like as the button is always focus
    if (e.screenX === 0 || e.screenY === 0) {
      return;
    }

    this.displayGalleryDialog = true;
  }

  ngOnInit() {
    // Initially, show the pre-select picture index or use the first picture
    this.onPictureSelected(Math.max(0, this.config.data.indexMainImage));
  }

  onPictureSelected(index: number) {
    if (this.imagesFormArray.at(index)) {
      this._activePicture = this.imagesFormArray.at(index);

      if (this.pinnedChangedSubscription) this.pinnedChangedSubscription.unsubscribe();
      this.pinnedChangedSubscription = this._activePicture
        .get("pinToPictureGallery")
        .valueChanges.subscribe((isPinned) => {
          if (isPinned) this.onNewPicturePinned();
        });
    }
  }

  private onAddNewPicture(response: { fileName: string; bucketPath: string }) {
    const inventoryImage = new InventoryImage();
    inventoryImage.fileName = response.fileName;
    inventoryImage.urlFileName = response.bucketPath + "/" + response.fileName;

    this.imagesFormArray.insert(0, InventoryImageComponent.set(this.fb, inventoryImage));
  }

  private onNewPicturePinned() {
    // Uncheck any previously pinned picture
    for (let i = 0; i < this.imagesFormArray.length; i++) {
      const theImageForm = this.imagesFormArray.at(i);
      if (theImageForm !== this._activePicture)
        theImageForm.get("pinToPictureGallery").setValue(false, { emitEvent: false });
    }
  }

  onRemoveActivePicture() {
    // Delete current active picture
    for (let i = 0; i < this.imagesFormArray.length; i++) {
      const theImageForm = this.imagesFormArray.at(i);
      if (theImageForm === this._activePicture) {
        this.imagesFormArray.removeAt(i);
        this.imagesFormArray.markAsDirty();
        this._activePicture = null;
        return;
      }
    }
  }

  buildImageSrc(imageForm: AbstractControl) {
    return imageForm.get("urlFileName").value;
    // return WebHelpers.buildImageUrl(imageForm.get('fileName').value);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
